<style>*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
}

.background {
  background: white;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9CYWNrZ3JvdW5kLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTs7O0VBR0Esc0JBQXNCO0FBQ3RCOztBQUNBOztFQUVBLFNBQVM7QUFDVDs7QUFFQTtFQUNBLGlCQUFpQjtFQUNqQixlQUFlO0VBQ2YsWUFBWTtFQUNaLGFBQWE7RUFDYixNQUFNO0FBQ04iLCJmaWxlIjoic3JjL0JhY2tncm91bmQuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4qLFxuKjo6YmVmb3JlLFxuKjo6YWZ0ZXIge1xuYm94LXNpemluZzogYm9yZGVyLWJveDtcbn1cbmh0bWwsXG5ib2R5IHtcbm1hcmdpbjogMDtcbn1cblxuLmJhY2tncm91bmQge1xuYmFja2dyb3VuZDogd2hpdGU7XG5wb3NpdGlvbjogZml4ZWQ7XG53aWR0aDogMTAwdnc7XG5oZWlnaHQ6IDEwMHZoO1xudG9wOiAwO1xufVxuIl19 */</style>
<section class="background">
    <ul class="background-grid">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
    <div class="background-blurs">
        <div class="blur-1"><span></span></div>
        <div class="blur-2"><span></span></div>
        <div class="blur-3"><span></span></div>
    </div>
</section>