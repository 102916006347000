<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  onMount(async () => {});
</script>

<div class="container">
  <div class="body">
    <p>
      You can get the objects from GET endpoints (see endpoints section)
    </p>
    <h4>Default Objects</h4>
    <div class="table-wrap">
      <table>
        <tr><td>ConversationInfo</td><td>information about the conversation</td></tr>
        <tr><td>Messages</td><td>messages of the conversation</td></tr>
      </table>
    </div>
    <h4>Object Arrays</h4>
    <p><b>- ConversationInfo</b></p>
    <p>From GET https://api.qix.cloud/conversation</p>
    <div class="table-wrap">
      <table>
        <tr><td>id</td><td>res.id (string)</td></tr>
        <tr><td>appId</td><td>res.appId (string)</td></tr>
        <tr><td>chatType</td><td>res.chatType (string)</td></tr>
        <tr><td>chatStatus</td><td>res.chatStatus (string)</td></tr>
        <tr><td>language</td><td>res.language (string)</td></tr>
        <tr><td>fullName</td><td>res.fullName (string)</td></tr>
        <tr><td>phone</td><td>res.phone (string)</td></tr>
        <tr><td>email</td><td>res.email (string)</td></tr>
        <tr><td>birthday</td><td>res.birthday (string)</td></tr>
        <tr><td>accountStatus</td><td>res.accountStatus (string)</td></tr>
        <tr><td>lastActivity</td><td>res.lastActivity (number)</td></tr>
        <tr><td>lastMessage.text</td><td>lastMessage.text (string)</td></tr>
        <tr><td>lastMessage.author</td><td>lastMessage.author (string)</td></tr>
        <tr><td>cases</td><td>res.cases (Case Matters Array)</td></tr>
        <tr><td>party</td><td>res.party (Party Info Array)</td></tr>
        <tr><td>tags</td><td>res.tags (Tags Array)</td></tr>
        <tr><td>events</td><td>res.events (Events Array)</td></tr>
        <tr><td>members</td><td>res.members (Members Array)</td></tr>
      </table>
    </div>
    
    <p><b>- Case Matters</b></p>
    <p>Part of ConversationInfo</p>
    <div class="table-wrap">
      <table>
        <tr><td>name</td><td>res.cases[index].name (string)</td></tr>
        <tr><td>details</td><td>res.cases[index].details (string)</td></tr>
        <tr><td>creator</td><td>res.cases[index].creator (string)</td></tr>
        <tr><td>recipient</td><td>res.cases[index].recipient (string)</td></tr>
        <tr><td>timestamp</td><td>res.cases[index].timestamp (number)</td></tr>
      </table>
    </div>

    <p><b>- Party Info</b></p>
    <p>Part of ConversationInfo</p>
    <div class="table-wrap">
      <table>
        <tr><td>name</td><td>res.party[index].name (string)</td></tr>
        <tr><td>details</td><td>res.party[index].details (string)</td></tr>
        <tr><td>creator</td><td>res.party[index].creator (string)</td></tr>
        <tr><td>recipient</td><td>res.party[index].recipient (string)</td></tr>
        <tr><td>address1</td><td>res.party[index].address1 (string)</td></tr>
        <tr><td>address2</td><td>res.party[index].address2 (string)</td></tr>
        <tr><td>city</td><td>res.party[index].city (string)</td></tr>
        <tr><td>country</td><td>res.party[index].country (string)</td></tr>
        <tr><td>zipCode</td><td>res.party[index].zipCode (string)</td></tr>
        <tr><td>timestamp</td><td>res.party[index].timestamp (number)</td></tr>
      </table>
    </div>

    <p><b>- Tag</b></p>
    <p>Part of ConversationInfo</p>
    <div class="table-wrap">
      <table>
        <tr><td>name</td><td>res.tags[index].name (string)</td></tr>
        <tr><td>id</td><td>res.tags[index].id (string)</td></tr>
      </table>
    </div>

    <p><b>- Event</b></p>
    <p>Part of ConversationInfo</p>
    <div class="table-wrap">
      <table>
        <tr><td>name</td><td>res.events[index].name (string)</td></tr>
        <tr><td>details</td><td>res.events[index].details (string)</td></tr>
        <tr><td>eventTimestamp</td><td>res.events[index].eventTimestamp (number)</td></tr>
        <tr><td>eventDate</td><td>res.events[index].eventDate (string)</td></tr>
        <tr><td>eventTime</td><td>res.events[index].eventTime (string)</td></tr>
        <tr><td>eventType</td><td>res.events[index].eventType (string)</td></tr>
        <tr><td>members</td><td>res.events[index].members (string[])</td></tr>
        <tr><td>location</td><td>res.events[index].location (string)</td></tr>
        <tr><td>creator</td><td>res.events[index].creator (string)</td></tr>
        <tr><td>timestamp</td><td>res.events[index].timestamp (number)</td></tr>
      </table>
    </div>

    <p><b>- Member</b></p>
    <p>Part of ConversationInfo</p>
    <div class="table-wrap">
      <table>
        <tr><td>id</td><td>res.members[index].id (string)</td></tr>
        <tr><td>label</td><td>res.members[index].label (string)</td></tr>
        <tr><td>img</td><td>res.members[index].img (string)</td></tr>
        <tr><td>status</td><td>res.members[index].status (string)</td></tr>
        <tr><td>newMessages</td><td>res.members[index].newMessages (number)</td></tr>
        <tr><td>role</td><td>res.members[index].role (string)</td></tr>
      </table>
    </div>

    <p><b>- Message</b></p>
    <p>From GET https://api.qix.cloud/messages?chat=chatId</p>
    <div class="table-wrap">
      <table>
        <tr><td>id</td><td>res[index].id (string)</td></tr>
        <tr><td>text</td><td>res[index].text (string)</td></tr>
        <tr><td>date</td><td>res[index].date (number)</td></tr>
        <tr><td>author</td><td>res[index].author (string)</td></tr>
        <tr><td>authorLabel</td><td>res[index].authorLabel (string)</td></tr>
        <tr><td>attachment</td><td>res[index].attachment (string)</td></tr>
        <tr><td>source</td><td>res[index].source (string)</td></tr>
        <tr><td>twilioStatus</td><td>res[index].twilioStatus (string)</td></tr>
      </table>
    </div>
  </div>
</div>

<style>.body h4 {
  font-weight: bold;
  padding: 15px 0 5px 0;
  font-size: 1.1rem;
}

.body {
  margin-top: 30px;
}

.body p {
  margin-bottom: 10px;
}

.body table {
  width: 100%;
  margin-bottom: 20px;
}

.body table tr:nth-child(odd) {
  background: #dde3fd;
}

.body table tr:nth-child(even) {
  background: #edf2f6;
}

.body table tr td {
  padding: 5px 10px;
}

.body table table {
  border: 1px solid #fff;
  margin: 10px 0;
}

.table-wrap {
  width: 100%;
  overflow: auto;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jaGF0L09iamVjdHMuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0UsaUJBQWlCO0VBQ2pCLHFCQUFxQjtFQUNyQixpQkFBaUI7QUFDbkI7O0FBQ0E7RUFDRSxnQkFBZ0I7QUFDbEI7O0FBQ0E7RUFDRSxtQkFBbUI7QUFDckI7O0FBQ0E7RUFDRSxXQUFXO0VBQ1gsbUJBQW1CO0FBQ3JCOztBQUNBO0VBQ0UsbUJBQW1CO0FBQ3JCOztBQUVBO0VBQ0UsbUJBQW1CO0FBQ3JCOztBQUNBO0VBQ0UsaUJBQWlCO0FBQ25COztBQUNBO0VBQ0Usc0JBQXNCO0VBQ3RCLGNBQWM7QUFDaEI7O0FBQ0E7RUFDRSxXQUFXO0VBQ1gsY0FBYztBQUNoQiIsImZpbGUiOiJzcmMvY2hhdC9PYmplY3RzLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLmJvZHkgaDQge1xuICBmb250LXdlaWdodDogYm9sZDtcbiAgcGFkZGluZzogMTVweCAwIDVweCAwO1xuICBmb250LXNpemU6IDEuMXJlbTtcbn1cbi5ib2R5IHtcbiAgbWFyZ2luLXRvcDogMzBweDtcbn1cbi5ib2R5IHAge1xuICBtYXJnaW4tYm90dG9tOiAxMHB4O1xufVxuLmJvZHkgdGFibGUge1xuICB3aWR0aDogMTAwJTtcbiAgbWFyZ2luLWJvdHRvbTogMjBweDtcbn1cbi5ib2R5IHRhYmxlIHRyOm50aC1jaGlsZChvZGQpIHtcbiAgYmFja2dyb3VuZDogI2RkZTNmZDtcbn1cblxuLmJvZHkgdGFibGUgdHI6bnRoLWNoaWxkKGV2ZW4pIHtcbiAgYmFja2dyb3VuZDogI2VkZjJmNjtcbn1cbi5ib2R5IHRhYmxlIHRyIHRkIHtcbiAgcGFkZGluZzogNXB4IDEwcHg7XG59XG4uYm9keSB0YWJsZSB0YWJsZSB7XG4gIGJvcmRlcjogMXB4IHNvbGlkICNmZmY7XG4gIG1hcmdpbjogMTBweCAwO1xufVxuLnRhYmxlLXdyYXAge1xuICB3aWR0aDogMTAwJTtcbiAgb3ZlcmZsb3c6IGF1dG87XG59XG4iXX0= */</style>
