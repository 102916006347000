<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  onMount(async () => {});
</script>

<div class="container">
  <div class="body">
    <h4>Scripts</h4>
    <p>Start testing the API with a Sample Auth Script</p>
    <a
      href="https://github.com/qixcloud/Testing/blob/main/Auth.js"
      target="_blank"
    >
      <div class="flex items-center cursor-pointer link-wrap">
        <img
          src="/react-native.png"
          class="cursor-pointer"
          style="width: 35px;margin-right: 15px;"
        />
        <div>App API Auth Script for React Native</div>
      </div>
    </a>
  </div>
</div>

<style>.body h4 {
  font-weight: bold;
  padding: 15px 0 5px 0;
  font-size: 1.1rem;
}

.body {
  margin-top: 30px;
}

.body p {
  margin-bottom: 10px;
}

.link-wrap {
  background: #dee3fe;
  padding: 10px 20px;
  border-radius: 10px;
  max-width: 360px;
  justify-content: space-between;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9hcHAvR2l0aHViLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLGlCQUFpQjtFQUNqQixxQkFBcUI7RUFDckIsaUJBQWlCO0FBQ25COztBQUNBO0VBQ0UsZ0JBQWdCO0FBQ2xCOztBQUNBO0VBQ0UsbUJBQW1CO0FBQ3JCOztBQUNBO0VBQ0UsbUJBQW1CO0VBQ25CLGtCQUFrQjtFQUNsQixtQkFBbUI7RUFDbkIsZ0JBQWdCO0VBQ2hCLDhCQUE4QjtBQUNoQyIsImZpbGUiOiJzcmMvYXBwL0dpdGh1Yi5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5ib2R5IGg0IHtcbiAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gIHBhZGRpbmc6IDE1cHggMCA1cHggMDtcbiAgZm9udC1zaXplOiAxLjFyZW07XG59XG4uYm9keSB7XG4gIG1hcmdpbi10b3A6IDMwcHg7XG59XG4uYm9keSBwIHtcbiAgbWFyZ2luLWJvdHRvbTogMTBweDtcbn1cbi5saW5rLXdyYXAge1xuICBiYWNrZ3JvdW5kOiAjZGVlM2ZlO1xuICBwYWRkaW5nOiAxMHB4IDIwcHg7XG4gIGJvcmRlci1yYWRpdXM6IDEwcHg7XG4gIG1heC13aWR0aDogMzYwcHg7XG4gIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2Vlbjtcbn1cbiJdfQ== */</style>
