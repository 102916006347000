<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  import ContentApp from "./ContentApp.svelte";
  import ContentChat from "./ContentChat.svelte";
  export let user_data;
  const dispatch = createEventDispatcher();
  //const currentYear = new Date().getFullYear();
  let firstLoading = false;
  let tab = "app";
  onMount(async () => {
    setTimeout(() => {
      firstLoading = true;
    }, 1000);
  });
  function onHide() {
    dispatch("hide");
  }
</script>

{#if firstLoading}
  <div class="wrap container">
    <div class="child-header flex">
      <div
        class="tab flex items-center"
        class:act={tab == "app"}
        on:click={() => (tab = "app")}
      >
        <img src="/Mobile_App_logo_256.png" /> App
      </div>
      <div
        class="tab flex items-center"
        class:act={tab == "chat"}
        on:click={() => (tab = "chat")}
      >
        <img src="/qix_chat_logo.png" /> Chat
      </div>
    </div>
    {#if tab == "app"}
      <ContentApp {user_data} />
    {/if}
    {#if tab == "chat"}
      <ContentChat {user_data} />
    {/if}
  </div>
{:else}
  <div
    class="w-full flex-grow flex justify-center items-center"
    style="height: 100vh;position: fixed;background: #fff;top: 0;left: 0;z-index: 99;"
  >
    <img src="/EmoteHi.gif" />
  </div>
{/if}

<style>input[type="text"],
textarea {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
}

.wrap {
  position: relative;
  background: #fff;
  padding: 20px 30px;
  border-radius: 15px;
  max-width: 768px;
  /* border: 3px dashed #f1f3f6; */
}

.wrap .child-header {
  margin-bottom: 20px;
}

.wrap .child-header .tab {
  font-size: 1.2em;
  padding: 8px 16px;
  border-radius: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.wrap .child-header .tab.act {
  background: #d2e9f5;
}

.wrap .child-header .tab img {
  width: 30px;
  margin-right: 10px;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9Db250ZW50cy5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0E7O0VBRUUsaUJBQWlCO0VBQ2pCLHNCQUFzQjtFQUN0QixtQkFBbUI7RUFDbkIsZ0JBQWdCO0VBQ2hCLFdBQVc7QUFDYjs7QUFDQTtFQUNFLGtCQUFrQjtFQUNsQixnQkFBZ0I7RUFDaEIsa0JBQWtCO0VBQ2xCLG1CQUFtQjtFQUNuQixnQkFBZ0I7RUFDaEIsZ0NBQWdDO0FBQ2xDOztBQUNBO0VBQ0UsbUJBQW1CO0FBQ3JCOztBQUNBO0VBQ0UsZ0JBQWdCO0VBQ2hCLGlCQUFpQjtFQUNqQixtQkFBbUI7RUFDbkIsa0JBQWtCO0VBQ2xCLGVBQWU7QUFDakI7O0FBQ0E7RUFDRSxtQkFBbUI7QUFDckI7O0FBQ0E7RUFDRSxXQUFXO0VBQ1gsa0JBQWtCO0FBQ3BCIiwiZmlsZSI6InNyYy9Db250ZW50cy5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbmlucHV0W3R5cGU9XCJ0ZXh0XCJdLFxudGV4dGFyZWEge1xuICBwYWRkaW5nOiA1cHggMTBweDtcbiAgYm9yZGVyOiAxcHggc29saWQgI2NjYztcbiAgYm9yZGVyLXJhZGl1czogMTBweDtcbiAgbWFyZ2luLXRvcDogMTBweDtcbiAgd2lkdGg6IDEwMCU7XG59XG4ud3JhcCB7XG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgYmFja2dyb3VuZDogI2ZmZjtcbiAgcGFkZGluZzogMjBweCAzMHB4O1xuICBib3JkZXItcmFkaXVzOiAxNXB4O1xuICBtYXgtd2lkdGg6IDc2OHB4O1xuICAvKiBib3JkZXI6IDNweCBkYXNoZWQgI2YxZjNmNjsgKi9cbn1cbi53cmFwIC5jaGlsZC1oZWFkZXIge1xuICBtYXJnaW4tYm90dG9tOiAyMHB4O1xufVxuLndyYXAgLmNoaWxkLWhlYWRlciAudGFiIHtcbiAgZm9udC1zaXplOiAxLjJlbTtcbiAgcGFkZGluZzogOHB4IDE2cHg7XG4gIGJvcmRlci1yYWRpdXM6IDIwcHg7XG4gIG1hcmdpbi1yaWdodDogMjBweDtcbiAgY3Vyc29yOiBwb2ludGVyO1xufVxuLndyYXAgLmNoaWxkLWhlYWRlciAudGFiLmFjdCB7XG4gIGJhY2tncm91bmQ6ICNkMmU5ZjU7XG59XG4ud3JhcCAuY2hpbGQtaGVhZGVyIC50YWIgaW1nIHtcbiAgd2lkdGg6IDMwcHg7XG4gIG1hcmdpbi1yaWdodDogMTBweDtcbn1cbiJdfQ== */</style>
