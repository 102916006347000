<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  onMount(async () => {});
</script>

<div class="container">
  <div class="body">
    <h4>Scripts</h4>
    <p>Start testing the API with sample scripts</p>
    <a
      href="https://github.com/qixcloud/api-samples/tree/main"
      target="_blank"
    >
      <div class="flex items-center cursor-pointer link-wrap">
        <div>Sample Scripts</div>
      </div>
    </a>
  </div>
</div>

<style>.body h4 {
  font-weight: bold;
  padding: 15px 0 5px 0;
  font-size: 1.1rem;
}

.body {
  margin-top: 30px;
}

.body p {
  margin-bottom: 10px;
}

.link-wrap {
  background: #dee3fe;
  padding: 10px 20px;
  border-radius: 10px;
  max-width: 360px;
  justify-content: space-between;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jaGF0L0dpdGh1Yi5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0E7RUFDRSxpQkFBaUI7RUFDakIscUJBQXFCO0VBQ3JCLGlCQUFpQjtBQUNuQjs7QUFDQTtFQUNFLGdCQUFnQjtBQUNsQjs7QUFDQTtFQUNFLG1CQUFtQjtBQUNyQjs7QUFDQTtFQUNFLG1CQUFtQjtFQUNuQixrQkFBa0I7RUFDbEIsbUJBQW1CO0VBQ25CLGdCQUFnQjtFQUNoQiw4QkFBOEI7QUFDaEMiLCJmaWxlIjoic3JjL2NoYXQvR2l0aHViLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLmJvZHkgaDQge1xuICBmb250LXdlaWdodDogYm9sZDtcbiAgcGFkZGluZzogMTVweCAwIDVweCAwO1xuICBmb250LXNpemU6IDEuMXJlbTtcbn1cbi5ib2R5IHtcbiAgbWFyZ2luLXRvcDogMzBweDtcbn1cbi5ib2R5IHAge1xuICBtYXJnaW4tYm90dG9tOiAxMHB4O1xufVxuLmxpbmstd3JhcCB7XG4gIGJhY2tncm91bmQ6ICNkZWUzZmU7XG4gIHBhZGRpbmc6IDEwcHggMjBweDtcbiAgYm9yZGVyLXJhZGl1czogMTBweDtcbiAgbWF4LXdpZHRoOiAzNjBweDtcbiAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xufVxuIl19 */</style>
