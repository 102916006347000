<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import {
    readAsArrayBuffer,
    readAsImage,
    readAsPDF,
    readAsDataURL,
  } from "./utils/asyncReader.js";
  export let user_data;
  import { clickOutside } from "./utils/clickOutside.js";
  import Overview from "./app/Overview.svelte";
  import Endpoints from "./app/Endpoints.svelte";
  import Objects from "./app/Objects.svelte";
  import Github from "./app/Github.svelte";
  import Billing from "./app/Billing.svelte";
  const dispatch = createEventDispatcher();
  //const currentYear = new Date().getFullYear();
  let menu = "Overview";
  let viewMenu = false;
  onMount(async () => {});
  function onHide() {
    dispatch("hide");
  }
  function updateMenu(item) {
    menu = item;
    viewMenu = false;
  }

  function handleClickOutsideHideMenu() {
    viewMenu = false;
  }
</script>

<div class="container">
  <div
    class="menu"
    use:clickOutside
    on:click_outside={handleClickOutsideHideMenu}
  >
    <div
      class="menu-header flex items-center"
      on:click={() => (viewMenu = true)}
    >
      {menu}
      <img src="/menu.png" />
    </div>
    {#if viewMenu}
      <div class="menu-items">
        <div
          class="item flex items-center"
          on:click={() => updateMenu("Overview")}
        >
          Overview
        </div>
        <div
          class="item flex items-center"
          on:click={() => updateMenu("Endpoints")}
        >
          Endpoints
        </div>
        <div
          class="item flex items-center"
          on:click={() => updateMenu("Objects")}
        >
          Objects
        </div>
        <div
          class="item flex items-center"
          on:click={() => updateMenu("Github")}
        >
          Github
        </div>
        <div
          class="item flex items-center"
          on:click={() => updateMenu("Billing")}
        >
          Billing
        </div>
      </div>
    {/if}
  </div>
  <div class="body">
    {#if menu == "Overview"}
      <Overview {user_data} />
    {:else if menu == "Endpoints"}
      <Endpoints {user_data} />
    {:else if menu == "Objects"}
      <Objects {user_data} />
    {:else if menu == "Github"}
      <Github {user_data} />
    {:else if menu == "Billing"}
      <Billing {user_data} />
    {/if}
  </div>
</div>

<style>input[type="text"],
textarea {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 10px;
  width: 100%;
}

.menu {
  position: relative;
  width: 150px;
  border-radius: 5px;
}

.menu .menu-header,
.menu .menu-items .item {
  width: 100%;
  justify-content: space-between;
  padding: 5px 10px;
  background: #cfe6f4;
}

.menu .menu-header,
.menu .menu-items {
  border-radius: 5px;
  overflow: hidden;
}

.menu .menu-header:hover,
.menu .menu-items .item:hover {
  cursor: pointer;
  background: #6ec4f3;
}

.menu .menu-items {
  position: absolute;
  width: 100%;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9Db250ZW50QXBwLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTs7RUFFRSxpQkFBaUI7RUFDakIsc0JBQXNCO0VBQ3RCLG1CQUFtQjtFQUNuQixnQkFBZ0I7RUFDaEIsV0FBVztBQUNiOztBQUNBO0VBQ0Usa0JBQWtCO0VBQ2xCLFlBQVk7RUFDWixrQkFBa0I7QUFDcEI7O0FBQ0E7O0VBRUUsV0FBVztFQUNYLDhCQUE4QjtFQUM5QixpQkFBaUI7RUFDakIsbUJBQW1CO0FBQ3JCOztBQUNBOztFQUVFLGtCQUFrQjtFQUNsQixnQkFBZ0I7QUFDbEI7O0FBQ0E7O0VBRUUsZUFBZTtFQUNmLG1CQUFtQjtBQUNyQjs7QUFDQTtFQUNFLGtCQUFrQjtFQUNsQixXQUFXO0FBQ2IiLCJmaWxlIjoic3JjL0NvbnRlbnRBcHAuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG5pbnB1dFt0eXBlPVwidGV4dFwiXSxcbnRleHRhcmVhIHtcbiAgcGFkZGluZzogNXB4IDEwcHg7XG4gIGJvcmRlcjogMXB4IHNvbGlkICNjY2M7XG4gIGJvcmRlci1yYWRpdXM6IDEwcHg7XG4gIG1hcmdpbi10b3A6IDEwcHg7XG4gIHdpZHRoOiAxMDAlO1xufVxuLm1lbnUge1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gIHdpZHRoOiAxNTBweDtcbiAgYm9yZGVyLXJhZGl1czogNXB4O1xufVxuLm1lbnUgLm1lbnUtaGVhZGVyLFxuLm1lbnUgLm1lbnUtaXRlbXMgLml0ZW0ge1xuICB3aWR0aDogMTAwJTtcbiAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xuICBwYWRkaW5nOiA1cHggMTBweDtcbiAgYmFja2dyb3VuZDogI2NmZTZmNDtcbn1cbi5tZW51IC5tZW51LWhlYWRlcixcbi5tZW51IC5tZW51LWl0ZW1zIHtcbiAgYm9yZGVyLXJhZGl1czogNXB4O1xuICBvdmVyZmxvdzogaGlkZGVuO1xufVxuLm1lbnUgLm1lbnUtaGVhZGVyOmhvdmVyLFxuLm1lbnUgLm1lbnUtaXRlbXMgLml0ZW06aG92ZXIge1xuICBjdXJzb3I6IHBvaW50ZXI7XG4gIGJhY2tncm91bmQ6ICM2ZWM0ZjM7XG59XG4ubWVudSAubWVudS1pdGVtcyB7XG4gIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgd2lkdGg6IDEwMCU7XG59XG4iXX0= */</style>
