<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  export let user_data;
  const dispatch = createEventDispatcher();
  import CopyClipBoard from "../CopyClipBoard.svelte";
  //const currentYear = new Date().getFullYear();
  onMount(async () => {});
</script>

<div class="container">
  <div class="body">
    <h4>Login Endpoints</h4>
    <p>For sending 2fa code to user</p>
    <pre>
      POST https://api.qix.cloud/sendCode
      Body (JSON) 
        - phone: string
    </pre>
    <br>
    <p>For getting JWT for further requests</p>
    <pre>
      POST https://api.qix.cloud/sign/phone
      Body (JSON)
        - phone: string
        - app: string
        - code: number
    </pre>
    <p
      class="p-5 flex items-center"
      style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
    >
      Code for "sendCode" <a
        href="https://github.com/qixcloud/api-samples/blob/main/sendCode.ts"
        target="_blank"
        ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
      >
    </p>
    <p
      class="p-5 flex items-center"
      style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
    >
      Code for "sign/phone" <a
        href="https://github.com/qixcloud/api-samples/blob/main/signPhone.ts"
        target="_blank"
        ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
      >
    </p>
    <br><br>
    <h4>Get conversation</h4>
    <pre>
      GET https://api.qix.cloud/conversation
    </pre>
    <p
      class="p-5 flex items-center"
      style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
    >
      Code for getting conversation <a
        href="https://github.com/qixcloud/api-samples/blob/main/getConversation.ts"
        target="_blank"
        ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
      >
    </p>
    <h4>Edit conversation</h4>
    <pre>
      PUT https://api.qix.cloud/conversation
      Body (JSON)
        - id: string
        - language?: string
        - fullName?: string
        - phone?: string
        - email?: string
        - birthday?: string
        - accountStatus?: string
        - device?: string
        - intake_complete?: boolean
        - noteids?: string[]
        - cases?: &#123;
          name: string,
          details: string,
          creator: string,
          recipient: string,
          timestamp: number,
        &#125;[]
        - party?: &#123;
          name: string,
          details: string,
          creator: string,
          recipient: string,
          address1: string,
          address2: string,
          city: string,
          country: string,
          zipCode: string,
          timestamp: number,
        &#125;[]
        - tags?: &#123;
          name: string,
          id: string,
        &#125;[]
        - events?: &#123;
          name: string,
          details: string,
          eventTimestamp: number,
          eventType: string,
          members: string[],
          location: string,
          creator: string,
          timestamp: number,
          demo: boolean,
        &#125;[]
        - advanced?: &#123;
          fileVineProjectIds: string[],
          caseFileIds: string[],
        &#125;
    </pre>
    <p
      class="p-5 flex items-center"
      style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
    >
      Code for editing conversation <a
        href="https://github.com/qixcloud/api-samples/blob/main/editConversation.ts"
        target="_blank"
        ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
      >
    </p>
    <h4>Get Messages</h4>
    <pre>
      GET https://api.qix.cloud/messages?chat=chatId
    </pre>
    <p
      class="p-5 flex items-center"
      style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
    >
      Code for getting messages <a
        href="https://github.com/qixcloud/api-samples/blob/main/getMessages.ts"
        target="_blank"
        ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
      >
    </p>
    <h4>Send Message</h4>
    <pre>
      POST https://api.qix.cloud/Message
      Body (JSON)
        - chat: string
        - message: string
        - attachment: string (base64)
        - attachmentMimeType: string
        - noteId: string
        - projectId: string
    </pre>
    <p
      class="p-5 flex items-center"
      style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
    >
      Code for getting messages <a
        href="https://github.com/qixcloud/api-samples/blob/main/sendMessage.ts"
        target="_blank"
        ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
      >
    </p>
    <h4>Set FCM token for conversation</h4>
    <pre>
      PUT https://api.qix.cloud/conversation/:conversationId/fcm
      Body (JSON)
        - fcm: string
    </pre>
    <p
      class="p-5 flex items-center"
      style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
    >
      Code for setting FCM token <a
        href="https://github.com/qixcloud/api-samples/blob/main/setFCM.ts"
        target="_blank"
        ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
      >
    </p>
    <h4>Set LegalPal FCM token for conversation</h4>
    <pre>
      PUT https://api.qix.cloud/conversation/:conversationId/legalpal_app_fcm
      Body (JSON)
        - fcm: string
    </pre>
    <p
      class="p-5 flex items-center"
      style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
    >
      Code for setting LegalPal FCM token <a
        href="https://github.com/qixcloud/api-samples/blob/main/set_legalpal_app_fcm.ts"
        target="_blank"
        ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
      >
    </p>
    <h4>Delete conversation</h4>
    <pre>
      DELETE https://api.qix.cloud/conversation/:conversationId
    </pre>
    <p
      class="p-5 flex items-center"
      style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
    >
      Code for deleting conversation <a
        href="https://github.com/qixcloud/api-samples/blob/main/deleteConversation.ts"
        target="_blank"
        ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
      >
    </p>
    <h4>Get App Version</h4>
    <pre>
      GET https://api.qix.cloud/app/version
    </pre>
    <p
      class="p-5 flex items-center"
      style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
    >
      Code for getting App Version <a
        href="https://github.com/qixcloud/api-samples/blob/main/getAppVersion.ts"
        target="_blank"
        ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
      >
    </p>
    <h4>Set App Version</h4>
    <pre>
      PUT https://api.qix.cloud/app/version
      Body (JSON)
        - version: string (numeric)
    </pre>
    <p
      class="p-5 flex items-center"
      style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
    >
      Code for setting App Version <a
        href="https://github.com/qixcloud/api-samples/blob/main/setAppVersion.ts"
        target="_blank"
        ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
      >
    </p>
    <h4>Get FileVine Phases</h4>
    <pre>
      GET https://api.qix.cloud/phaseFileVine
    </pre>
    <p
      class="p-5 flex items-center"
      style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
    >
      Code for getting FileVine Phases <a
        href="https://github.com/qixcloud/api-samples/blob/main/getFileVinePhases.ts"
        target="_blank"
        ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
      >
    </p>
    <h4>Get MerusCase Phases</h4>
    <pre>
      GET https://api.qix.cloud/phaseMerusCase
    </pre>
    <p
      class="p-5 flex items-center"
      style="background: #dde3fd;justify-content: space-between;border-radius: 10px;"
    >
      Code for getting MerusCase Phases <a
        href="https://github.com/qixcloud/api-samples/blob/main/getMerusCasePhases.ts"
        target="_blank"
        ><img src="/arrow_circle_right.png" class="cursor-pointer" /></a
      >
    </p>
  </div>
</div>

<style>.body {
  margin-top: 30px;
}

.body p {
  margin-bottom: 10px;
}

.body h4 {
  font-weight: bold;
  padding: 15px 0 5px 0;
  font-size: 1.1rem;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jaGF0L0VuZHBvaW50cy5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0E7RUFDRSxnQkFBZ0I7QUFDbEI7O0FBQ0E7RUFDRSxtQkFBbUI7QUFDckI7O0FBQ0E7RUFDRSxpQkFBaUI7RUFDakIscUJBQXFCO0VBQ3JCLGlCQUFpQjtBQUNuQiIsImZpbGUiOiJzcmMvY2hhdC9FbmRwb2ludHMuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4uYm9keSB7XG4gIG1hcmdpbi10b3A6IDMwcHg7XG59XG4uYm9keSBwIHtcbiAgbWFyZ2luLWJvdHRvbTogMTBweDtcbn1cbi5ib2R5IGg0IHtcbiAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gIHBhZGRpbmc6IDE1cHggMCA1cHggMDtcbiAgZm9udC1zaXplOiAxLjFyZW07XG59XG4iXX0= */</style>
