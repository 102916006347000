<svelte:options immutable={true} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  onMount(async () => {});
</script>

<div class="container">
  <div class="body">
    <p>
      The given JSON consists of Objects that store specific values within an
      Array. These Objects collectively provide a comprehensive range of data
      necessary for managing and customizing an application.
    </p>
    <p class="p-5" style="background: rgb(237, 242, 246);border-radius: 10px;">
      Example: sliders = appSettings.sliders;
    </p>
    <h4>Object Arrays</h4>
    <div class="table-wrap">
      <table>
        <tr><td>appName</td><td>appSettings.appName(String)</td></tr>
        <tr><td>Sections</td><td>appSettings.sections(Array)</td></tr>
        <tr><td>Sliders</td><td>appSettings.sliders(Object)</td></tr>
        <tr><td>Posts</td><td>appSettings.posts(Array)</td></tr>
        <tr><td>Status</td><td>appSettings.status(Array)</td></tr>
        <tr><td>Colors</td><td>appSettings.colors(Object)</td></tr>
        <tr><td>Icons</td><td>appSettings.icons(Object)</td></tr>
        <tr><td>BottomVisible</td><td>appSettings.bottomVisible(Int)</td></tr>
        <tr
          ><td>Business Address</td><td>appSettings.business_address(String)</td
          ></tr
        >
        <tr
          ><td>Business Email</td><td>appSettings.business_email(String)</td
          ></tr
        >
        <tr
          ><td>Business Phone</td><td>appSettings.business_phone(String)</td
          ></tr
        >
        <tr
          ><td>Facebook Link</td><td>appSettings.business_facebook(String)</td
          ></tr
        >
        <tr
          ><td>Instagram Link</td><td>appSettings.business_instagram(String)</td
          ></tr
        >
      </table>
    </div>
    <p><b>- Sliders</b></p>
    <div class="table-wrap">
      <table>
        <tr><td>Duration</td><td>appSettings.sliders.duration(Int)</td></tr>
        <tr
          ><td>Image</td><td>appSettings.sliders.data[index].img(String)</td
          ></tr
        >
        <tr
          ><td>Link</td><td>appSettings.sliders.data[index].link(String)</td
          ></tr
        >
      </table>
    </div>
    <p><b>- Posts</b></p>
    <div class="table-wrap">
      <table>
        <tr><td>Image</td><td>appSettings.posts[index].image(String)</td></tr>
        <tr><td>Title</td><td>appSettings.posts[index].title(String)</td></tr>
        <tr
          ><td>Description</td><td
            >appSettings.posts[index].description(String)</td
          ></tr
        >
      </table>
    </div>
    <p><b>- Status</b></p>
    <div class="table-wrap">
      <table>
        <tr><td>Title</td><td>appSettings.status[index].title(String)</td></tr>
        <tr><td>Text</td><td>appSettings.status[index].text(String)</td></tr>
        <tr
          ><td>Description</td><td
            >appSettings.status[index].description(String)</td
          ></tr
        >
      </table>
    </div>
    <p><b>- Colors</b></p>
    <div class="table-wrap">
      <table>
        <tr><td>Top</td><td>appSettings.colors.top(String)</td></tr>
        <tr><td>Body</td><td>appSettings.colors.body(String)</td></tr>
        <tr><td>Bottom</td><td>appSettings.colors.bottom(String)</td></tr>
      </table>
    </div>
    <p><b>- Icons</b></p>
    <div class="table-wrap">
      <table>
        <tr><td>Logo</td><td>appSettings.icons.app(String)</td></tr>
        <tr><td>userAvatar</td><td>appSettings.icons.userAvatar(String)</td></tr
        >
        <tr
          ><td>footer(Left)</td><td>appSettings.icons.footerIcon1(String)</td
          ></tr
        >
        <tr
          ><td>footer(Center)</td><td>appSettings.icons.footerIcon2(String)</td
          ></tr
        >
        <tr
          ><td>footer(Right)</td><td>appSettings.icons.footerIcon3(String)</td
          ></tr
        >
      </table>
    </div>
  </div>
</div>

<style>.body h4 {
  font-weight: bold;
  padding: 15px 0 5px 0;
  font-size: 1.1rem;
}

.body {
  margin-top: 30px;
}

.body p {
  margin-bottom: 10px;
}

.body table {
  width: 100%;
  margin-bottom: 20px;
}

.body table tr:nth-child(odd) {
  background: #dde3fd;
}

.body table tr:nth-child(even) {
  background: #edf2f6;
}

.body table tr td {
  padding: 5px 10px;
}

.body table table {
  border: 1px solid #fff;
  margin: 10px 0;
}

.table-wrap {
  width: 100%;
  overflow: auto;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9hcHAvT2JqZWN0cy5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0E7RUFDRSxpQkFBaUI7RUFDakIscUJBQXFCO0VBQ3JCLGlCQUFpQjtBQUNuQjs7QUFDQTtFQUNFLGdCQUFnQjtBQUNsQjs7QUFDQTtFQUNFLG1CQUFtQjtBQUNyQjs7QUFDQTtFQUNFLFdBQVc7RUFDWCxtQkFBbUI7QUFDckI7O0FBQ0E7RUFDRSxtQkFBbUI7QUFDckI7O0FBRUE7RUFDRSxtQkFBbUI7QUFDckI7O0FBQ0E7RUFDRSxpQkFBaUI7QUFDbkI7O0FBQ0E7RUFDRSxzQkFBc0I7RUFDdEIsY0FBYztBQUNoQjs7QUFDQTtFQUNFLFdBQVc7RUFDWCxjQUFjO0FBQ2hCIiwiZmlsZSI6InNyYy9hcHAvT2JqZWN0cy5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5ib2R5IGg0IHtcbiAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gIHBhZGRpbmc6IDE1cHggMCA1cHggMDtcbiAgZm9udC1zaXplOiAxLjFyZW07XG59XG4uYm9keSB7XG4gIG1hcmdpbi10b3A6IDMwcHg7XG59XG4uYm9keSBwIHtcbiAgbWFyZ2luLWJvdHRvbTogMTBweDtcbn1cbi5ib2R5IHRhYmxlIHtcbiAgd2lkdGg6IDEwMCU7XG4gIG1hcmdpbi1ib3R0b206IDIwcHg7XG59XG4uYm9keSB0YWJsZSB0cjpudGgtY2hpbGQob2RkKSB7XG4gIGJhY2tncm91bmQ6ICNkZGUzZmQ7XG59XG5cbi5ib2R5IHRhYmxlIHRyOm50aC1jaGlsZChldmVuKSB7XG4gIGJhY2tncm91bmQ6ICNlZGYyZjY7XG59XG4uYm9keSB0YWJsZSB0ciB0ZCB7XG4gIHBhZGRpbmc6IDVweCAxMHB4O1xufVxuLmJvZHkgdGFibGUgdGFibGUge1xuICBib3JkZXI6IDFweCBzb2xpZCAjZmZmO1xuICBtYXJnaW46IDEwcHggMDtcbn1cbi50YWJsZS13cmFwIHtcbiAgd2lkdGg6IDEwMCU7XG4gIG92ZXJmbG93OiBhdXRvO1xufVxuIl19 */</style>
